import '../../App.css';
import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { FileUploader } from "react-drag-drop-files";
import Swal from 'sweetalert2';
import UserApiClient from '../../ApiClients';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Sources({ data }) {

    const { chatbotData, User, Team } = data;

    const [value, setValue] = useState(0);
    const [Documents, setDocuments] = useState([]);
    const [Texts, setTexts] = useState([]);
    const [textHeading, setTextHeading] = useState('');
    const [textArea, setTextArea] = useState('');
    const [Websites, setWebsites] = useState([]);
    const [Qas, setQas] = useState([]);
    const [files, setFiles] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [urlFields, setUrlFields] = useState([]);
    const [processUrls, setProcessUrls] = useState([]);
    const [detectedChars, setDetectedChars] = useState(0);
    let isFetching = false;
    let isUploading = false;
    let isFetchingProcess = false;

    const handleFileChange = (fileList) => {
        setFiles(Array.from(fileList));
    };

    // Specify the allowed file types (adjust as needed)
    const fileTypes = ['pdf', 'docx', 'txt'];

    const handleDeleteField = (url) => {
        setUrlFields(urlFields.filter(field => field.url != url));
    };

    const handleDeleteProcessUrl = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await UserApiClient.delete(`bot/website/process/delete/${id}/`, {}, true);
                if (res.success) {
                    await fetchProcessUrls();
                    Swal.fire(res.data.message, '', 'success', 3000)
                }
            }
        });
    }

    const fetchProcessUrls = async () => {
        isFetchingProcess = true;
        const res = await UserApiClient.get(`bot/website/process/list/?chatbot_id=${chatbotData.id}`, {}, true, undefined, false);
        if (res.success) {
            setProcessUrls(res.data)
            isFetchingProcess = false;
        }
    };

    const handleUrlChecked = (index, e) => {
        const newUrlFields = [...urlFields];
        newUrlFields[index]['checked'] = e.target.checked;
        setInputs(newUrlFields);
    };

    const addInputField = () => {
        setInputs([...inputs,
        {
            "text_heading": "",
            "question": "",
            "answer": ""
        }
        ]);
    };

    const deleteInputField = (index) => {
        setInputs(inputs.filter((_, i) => i !== index));
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newInputs = [...inputs];
        newInputs[index][name] = value;
        setInputs(newInputs);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDelete = (e) => {
        const id = e.target.getAttribute('data-id');
        const type = e.target.getAttribute('data-type');
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                isUploading = true;
                if (type == 'document') {
                    await UserApiClient.delete(`bot/document/delete/${id}/`, {}, true).then(async res => {
                        await fetchData();
                        if (res.success) {
                            Swal.fire(res.data.message, '', 'success', 3000);
                        }
                    })
                } else if (type == 'text') {
                    await UserApiClient.delete(`bot/text/delete/${id}/`, {}, true).then(async res => {
                        await fetchData();
                        if (res.success) {
                            Swal.fire(res.data.message, '', 'success', 3000);
                        }
                    })
                } else if (type == 'url') {
                    await UserApiClient.delete(`bot/website/delete/${id}/`, {}, true).then(async res => {
                        await fetchData();
                        if (res.success) {
                            Swal.fire(res.data.message, '', 'success', 3000);
                        }
                    })
                } else if (type == 'qa') {
                    await UserApiClient.delete(`bot/qa/delete/${id}/`, {}, true).then(async res => {
                        await fetchData();
                        if (res.success) {
                            Swal.fire(res.data.message, '', 'success', 3000);
                        }
                    })
                }
                isUploading = false;
            }
        });
    };

    async function handleMultipleDelete(e) {
        const ids = [];
        var apiEndpoint;
        if (value === 0) {
            Documents.map(checkbox => {
                if (checkbox.checked) {
                    ids.push(checkbox.id)
                }
            });
            apiEndpoint = 'document'
        } else if (value === 1) {
            Texts.map(checkbox => {
                if (checkbox.checked) {
                    ids.push(checkbox.id)
                }
            });
            apiEndpoint = 'text'
        } else if (value === 2) {
            Websites.map(checkbox => {
                if (checkbox.checked) {
                    ids.push(checkbox.id)
                }
            });
            apiEndpoint = 'website'
        } else if (value === 3) {
            Qas.map(checkbox => {
                if (checkbox.checked) {
                    ids.push(checkbox.id)
                }
            });
            apiEndpoint = 'qa'
        }

        if (ids.length === 0 || !apiEndpoint) {
            Swal.fire('Please select at least a value.', '', 'error');
            return;
        }

        await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                isUploading = true;
                const res = await UserApiClient.delete(`bot/${apiEndpoint}/multi-delete/${chatbotData.id}/`, { ids: ids }, true);
                if (res.success) {
                    await fetchData();
                    Swal.fire(res.data.message, '', 'success', 3000)
                }
                isUploading = false;
            }
        });
    }

    const handleTempCheckboxChange = (e) => {
        const checked = e.target.checked;
        const newUrlFields = [...urlFields];
        newUrlFields.map(item => { item['checked'] = checked })
        setInputs(newUrlFields);
    }

    const handleMasterCheckboxChange = (e, type) => {
        const checked = e.target.checked;

        if (type == 'file') {
            const NewDocuments = [...Documents]
            NewDocuments.map(item => { item['checked'] = checked })
            setDocuments(NewDocuments);
        } else if (type == 'text') {
            const NewTexts = [...Texts]
            NewTexts.map(item => { item['checked'] = checked })
            setTexts(NewTexts);
        } else if (type == 'url') {
            const NewWebsites = [...Websites]
            NewWebsites.map(item => { item['checked'] = checked })
            setWebsites(NewWebsites);
        } else if (type == 'qa') {
            const NewQas = [...Qas]
            NewQas.map(item => { item['checked'] = checked })
            setQas(NewQas);
        }
    };

    async function fetchData(useLoader) {
        isFetching = true;
        const res = await UserApiClient.get(`bot/chatbot/sources/${chatbotData.id}/`, {}, true, undefined, useLoader);
        if (res.success) {
            setDocuments(res.data.documents)
            setTexts(res.data.texts)
            setWebsites(res.data.websites)
            setQas(res.data.qas)
            isFetching = false;
        }
    }

    async function hanadleFileSubmit(e) {
        e.preventDefault();
        if (files.length === 0) {
            Swal.fire('Please select at least a file.', '', 'error');
            return;
        }
        const formData = new FormData();
        files.map(file => { formData.append('files', file) })
        isUploading = true;
        await UserApiClient.post(`bot/document/${chatbotData.id}/`, formData, true).then(async res => {
            console.log(res)
            if (res.success) {
                setFiles([]);
                await fetchData();
                Swal.fire(res.data.message, '', 'success', 3000)
            } else if (res.status == 426) {
                setFiles([]);
                await fetchData();
            }
            isUploading = false;
        })
    }

    async function hanadleTextSubmit(e) {
        e.preventDefault();
        if (textHeading == "") {
            Swal.fire('Please add some subject to upload.', '', 'error');
            return;
        }
        if (textArea == "") {
            Swal.fire('Please add some text to upload.', '', 'error');
            return;
        }
        isUploading = true;
        await UserApiClient.post(`bot/text/${chatbotData.id}/`, { text: textArea, text_heading: textHeading }, true).then(async res => {
            if (res.success) {
                setTextArea('');
                setTextHeading('');
                await fetchData();
                Swal.fire(res.data.message, '', 'success', 3000)
            }
            isUploading = false;
        })
    }

    const getDomainFromUrl = (url) => {
        try {
            const parsedUrl = new URL(url);
            return parsedUrl.origin;
        } catch (error) {
            return null;
        }
    };

    async function hanadleUrlCrawlSubmit(e, typeUrl) {
        e.preventDefault();
        const url = e.target.url.value;
        const domain = getDomainFromUrl(url);

        if (typeUrl == 'Single') {
            await UserApiClient.post(`bot/extract/single-url/`, { url: url, chatbot_id: chatbotData.id }, true).then(async res => {
                if (res.success) {
                    e.target.reset();
                    setUrlFields([...urlFields, ...res.data.data]);
                    await fetchData();
                    fetchProcessUrls();
                    Swal.fire(`Url Fetched successfully.`, '', 'success', 3000)
                }
            })
        } else {
            await UserApiClient.post(`bot/website/process/add/`, {
                url: url,
                domain: domain,
                user: User.id,
                chatbot: chatbotData.id,
                type: typeUrl
            }, true).then(async res => {
                if (res.success) {
                    e.target.reset();
                    fetchProcessUrls();
                    Swal.fire(res.data.message, '', 'success', 3000);
                }
            })
        }
        // if (multiple) {
        //     await UserApiClient.post(`bot/extract/multi-url/`, { domain: url, user_id: User.id, chatbot_id: chatbotData.id }, true).then(async res => {
        //         if (res.success) {
        //             e.target.reset();
        //             setUrlFields([...urlFields, ...res.data.links_data]);
        //             await fetchData();
        //             fetchProcessUrls();
        //             Swal.fire(`Fetched ${res.data.counts.links_count} links and ${res.data.counts.total_char_count} Characters`, '', '', 3000)
        //         }
        //     })
        // } else {
        //     await UserApiClient.post(`bot/extract/single-url/`, { url: url, chatbot_id: chatbotData.id }, true).then(async res => {
        //         if (res.success) {
        //             e.target.reset();
        //             setUrlFields([...urlFields, ...res.data.data]);
        //             await fetchData();
        //             fetchProcessUrls();
        //             Swal.fire(`Url Fetched successfully.`, '', 'success', 3000)
        //         }
        //     })
        // }
    }

    // async function hanadleSitemapCrawlSubmit(e) {
    //     e.preventDefault();
    //     const url = e.target.sitemap_url.value

    //     await UserApiClient.post(`bot/extract/sitemap-url/`, { url: url, chatbot_id: chatbotData.id }, true).then(async res => {
    //         if (res.success) {
    //             e.target.reset();
    //             setUrlFields([...urlFields, ...res.data.links_data]);
    //             await fetchData();
    //             Swal.fire(`Fetched ${res.data.counts.links_count} links and ${res.data.counts.total_char_count} Characters`, '', '', 3000)
    //         }
    //     })
    // }

    const addUrlField = async () => {
        let valid = false;
        const data = []
        const url_data = [...urlFields]
        for (let urldata of url_data) {
            if (urldata.checked) {
                delete urldata['checked'];
                delete urldata['char'];
                data.push({ ...urldata });
                valid = true;
            }
        }
        if (!valid || url_data.length == 0 || data.length == 0) {
            Swal.fire('Please add Select at least a URL.', '', 'error');
            return;
        }

        const formData = new FormData();
        formData.append('links', JSON.stringify(data));
        isUploading = true;
        await UserApiClient.post(`bot/website/${chatbotData.id}/`, formData, true).then(async res => {
            if (res.success) {
                setUrlFields([]);
                await fetchData();
                fetchProcessUrls();
                Swal.fire(res.data.message, '', 'success', 3000)
            }
            isUploading = false;
        })
    };

    async function hanadleQaSubmit(e) {
        e.preventDefault();

        if (inputs.length === 0) {
            Swal.fire('Please add Questions/Answers to upload.', '', 'error');
            return;
        }
        for (let item of inputs) {
            if (item.text_heading === '' || item.question === '' || item.answer === '') {
                Swal.fire('Please fill all fields.', '', 'error');
                return;
            }
        }

        isUploading = true;
        await UserApiClient.post(`bot/qa/${chatbotData.id}/`, inputs, true).then(async res => {
            if (res.success) {
                setInputs([]);
                await fetchData();
                Swal.fire(res.data.message, '', 'success', 3000)
            }
            isUploading = false;
        })
    }

    async function handleSourceSearch(e) {
        e.preventDefault();
        const searchTerm = e.target.searchTerm.value;

        // Reset the documents based on the current value
        if (searchTerm.trim() === "") {
            // If searchTerm is empty, show original data
            await fetchData(false);
            return; // Exit the function early
        }

        // Filter based on the searchTerm
        if (value === 0) {
            const filteredData = Documents.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setDocuments(filteredData);
        } else if (value === 1) {
            const filteredData = Texts.filter(item =>
                item.text_heading.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setTexts(filteredData);
        } else if (value === 2) {
            const filteredData = Websites.filter(item =>
                item.url.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setWebsites(filteredData);
        } else if (value === 3) {
            const filteredData = Qas.filter(item =>
                item.text_heading.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setQas(filteredData);
        }
    }


    useEffect(() => {
        if (chatbotData.id) {
            if (!isFetching) {
                fetchData();
            }
            if (!isFetchingProcess) {
                fetchProcessUrls();
            }
            const intervalId = setInterval(async () => { if (!isUploading || !isFetchingProcess || !isFetching) await fetchData(false) }, 10000);
            const processIntervalId = setInterval(async () => { if (!isUploading || !isFetchingProcess || !isFetching) await fetchProcessUrls() }, 10000);
            return () => {
                clearInterval(intervalId);
                clearInterval(processIntervalId);
            };
        }
    }, [chatbotData])


    useEffect(() => {
        let new_chars = 0
        Documents.map(item => { new_chars += item.no_of_characters || 0 })
        Texts.map(item => { new_chars += item.no_of_characters || 0 })
        Websites.map(item => { new_chars += item.no_of_characters || 0 })
        Qas.map(item => { new_chars += item.no_of_characters || 0 })
        setDetectedChars(new_chars)
    }, [Documents, Texts, Websites, Qas])


    return (
        <>
            <div className='row'>
                <div className='col-sm-12 col-md-3 col-lg-3 col-xl-2 sorcecontainer'>
                    <div className='containers p-0'>
                        <div className='p-1'>
                            <Box>
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    className="custom-tabs"
                                    sx={{
                                        borderRight: 1,
                                        borderColor: 'divider',
                                    }}
                                >
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center' }}><span><i className="fa-solid fa-file-import me-3"></i>Files</span></Box>} {...a11yProps(0)} />
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center' }}><span><i className="fa-solid fa-message me-3"></i>Text</span></Box>} {...a11yProps(1)} />
                                    {/* <Tab label={<Box sx={{ display: 'flex', alignItems: 'center' }}><span><i className="fa-brands fa-chrome me-3"></i>Website</span></Box>} {...a11yProps(2)} /> */}
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center' }}><span><i className="fa-solid fa-globe me-3"></i>Website</span></Box>} {...a11yProps(2)} />
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center' }}><span><i className="fa-solid fa-comments me-3"></i>Q&A</span></Box>} {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-9 col-lg-7 col-xl-7 sorcecontainers'>
                    <div className='custom-padding'>
                        <TabPanel value={value} index={0} sx={{ padding: '0px' }}>
                            <div className='containers p-0'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Files</strong></h4>
                                    {/* <h3 className='mx-4 my-2'>Files</h3> */}
                                </div>
                                <div className="upload">
                                    <FileUploader
                                        multiple={true}
                                        handleChange={handleFileChange}
                                        name="file"
                                        types={fileTypes}
                                    />
                                    <div className='py-2 text-start'>
                                        {files.length > 0
                                            ? files.map(file => <div className='mb-1' style={{ borderBottom: '1px solid #cbcbcb', padding: '3px 10px' }}><i className="fa-regular fa-file-lines me-1"></i>{file.name}</div>)
                                            :
                                            <div className='text-center'><span>No files uploaded yet</span></div>}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {/* <div className='text-container px-0'>
                                <h4 className='mx-4 my-2'><strong>Name of Text block</strong></h4>
                                <sapn className='mx-4 my-2'>Dashboards Files</sapn>
                            </div> */}
                            <div className='containers p-0'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Text Block</strong></h4>
                                </div>
                                <div className="text-block my-3">
                                    <div className="form-group" >
                                        <input className="form-control" onChange={(e) => setTextHeading(e.target.value)} value={textHeading} placeholder='Title' maxLength={255}></input>
                                    </div>
                                    <div className="form-group" >
                                        <textarea className="form-control" rows="10" onChange={(e) => setTextArea(e.target.value)} value={textArea} placeholder='Text' />
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className='containers p-0'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Website</strong></h4>
                                </div>
                                <div className="text-block">
                                    <form onSubmit={(e) => hanadleUrlCrawlSubmit(e, 'Single')} className="urlfield my-3" >
                                        <label for="single_url">Single URL</label><br />
                                        <div className='row'>
                                            <div className='col-9'>
                                                <input type="url" id="single_url" name="url" placeholder='https://example.com/demo' required />
                                            </div>
                                            <div className='col-3'><button type="submit" className="btn btn-primary w-100 p-2">Fetch Link</button></div>
                                        </div>
                                    </form>
                                    <small id="Help" className="form-text text-muted">This is crawl the link starting with the URL (not including fields on the website).</small>
                                    <form onSubmit={(e) => hanadleUrlCrawlSubmit(e, 'Multi')} className="urlfield my-3" >
                                        <label for="url">Crawl URLs</label><br />
                                        <div className='row'>
                                            <div className='col-9'>
                                                <input type="url" id="url" name="url" placeholder='https://example.com/' required />
                                            </div>
                                            <div className='col-3'><button type="submit" className="btn btn-primary w-100 p-2">Fetch Links</button></div>
                                        </div>
                                    </form>
                                    <small id="Help" className="form-text text-muted">This is crawl all the links starting with the URL (not including fields on the website).</small>
                                    <div className='orline'><hr style={{ width: '40%' }} ></hr><p className='m-0'>OR</p><hr style={{ width: '40%' }}></hr></div>
                                    <form onSubmit={(e) => hanadleUrlCrawlSubmit(e, 'Sitemap')} className="urlfield mb-3" >
                                        <label for="sitemap_url">Submit Sitemap</label><br />
                                        <div className='row'>
                                            <div className='col-7'>
                                                <input type="url" id="sitemap_url" name="url" placeholder='https://example.com/sitemap.xml' required />
                                            </div>
                                            <div className='col-5'>
                                                <button type="submit" className="btn btn-primary w-100 p-2">Load Additional Sitemap</button>
                                            </div>
                                        </div>
                                    </form>

                                    <div class="accordion mb-4" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Under Process Urls
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body overflow-auto table-url-process p-2" style={{ maxHeight: '250px', scrollbarWidth: 'thin' }}>

                                                    <div className="table-responsive" style={{ scrollbarWidth: 'thin' }}>
                                                        <table className="table-bordr" style={{ border: "unset" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr.</th>
                                                                    <th>Domain</th>
                                                                    <th>Run time</th>
                                                                    <th>Type/Qty.</th>
                                                                    <th>Status</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {processUrls.map((field, index) => (
                                                                    <tr key={field.id}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{field.domain || field.url}</td>
                                                                        <td>{field.running_time}</td>
                                                                        <td>{field.type == 'Multi' ? "Url" : field.type}/{field.links_quantity}</td>
                                                                        <td>
                                                                            <span
                                                                                className='text-truncate text-capitalize'
                                                                                style={{
                                                                                    color: "#fff",
                                                                                    backgroundColor: field.status == 'Finished' ? '#30ad4d' : field.status == 'In Queue' ? '#b9bbbd' : field.status == 'Running' ? '#47a4e5' : '#ff3346',
                                                                                    borderRadius: "20px",
                                                                                    padding: "4px 15px"
                                                                                }}
                                                                            >
                                                                                {field.status}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <button type="button" className="icn-btn" >
                                                                                <i className="fa-solid fa-eye me-2" onClick={() => { if (field.processed) setUrlFields(field.json_data.links_data) }} style={{ color: '#3FA4E8' }}></i>
                                                                            </button>
                                                                            <button type="button" className="icn-btn" >
                                                                                <i className="fa-solid fa-trash-can me-2" onClick={() => handleDeleteProcessUrl(field.id)} style={{ color: 'red' }}></i>
                                                                            </button>

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="mb-4 overflow-auto" style={{ maxHeight: '300px', scrollbarWidth: 'thin' }} >
                                        {urlFields.length > 0 ? (
                                            <>
                                                {/* <div className="table-responsive  sss"> */}
                                                <div className="">
                                                    <table className="table-bordr" style={{ border: "unset" }}>
                                                        <thead>
                                                            <tr>
                                                                <th className='d-flex' style={{ padding: "20px 0" }}>
                                                                    <input
                                                                        className=""
                                                                        type="checkbox"
                                                                        onChange={handleTempCheckboxChange}
                                                                    />
                                                                    {/* <div className='ms-2'>Select all</div> */}
                                                                </th>
                                                                <th style={{ padding: "0px" }}>Select and train your chatbot (total {urlFields.length})</th>
                                                                <th style={{ display: "flex", justifyContent: "end", padding: "20px 0" }}> <button type="button" className="btn btn-outline-danger" onClick={() => setUrlFields([])}>Delete all</button></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {urlFields.map((field, index) => (
                                                                <tr key={field.id}>
                                                                    <td className='d-flex align-items-center' style={{ border: "unset", padding: "10px 0" }}>
                                                                        <input
                                                                            className="me-2"
                                                                            type="checkbox"
                                                                            data-id={field.id}
                                                                            onChange={(e) => handleUrlChecked(index, e)}
                                                                            checked={!!field.checked}
                                                                        // ref={(el) => tempUrlCheckboxesRef.current[index] = el}
                                                                        />
                                                                        {/* {field.index} */}
                                                                    </td>
                                                                    <td style={{ border: "1px solid #dee2e6", borderRadius: "5px", padding: "0px 20px" }}>
                                                                        <span className=''>{field.url}</span>
                                                                    </td>
                                                                    <td style={{ border: "unset", display: "flex", justifyContent: "end", padding: "10px 0" }}>
                                                                        {field.char}
                                                                        <button type="button" className="icn-btn"  >
                                                                            <img src="/icons/deletebtn.svg" className="tableimg" alt="logo" onClick={() => handleDeleteField(field.url)} />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </>
                                        ) : ''}


                                        {/* {urlFields.length > 0 ? (
                                                <div className='my-4' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <div className='d-flex'>
                                                        <div><input className="" type="checkbox"
                                                        //  checked={selectAll} 
                                                        //  onChange={handleSelectAllChange}
                                                        /></div>
                                                        <div className='ms-2'>Select all</div>
                                                    </div>
                                                    <strong>Select and train your chatbot</strong>
                                                    <button type="button" className="btn btn-outline-danger" onClick={() => setUrlFields([])}>Delete all</button>
                                                </div>
                                            ) : ''}
                                            {urlFields.map((field, index) => (
                                                <div key={index} className='urldatacheck d-flex align-items-center mb-2 pe-3'>
                                                    <div className='me-2'>
                                                        <input
                                                            className="mx-0"
                                                            type="checkbox"
                                                            onChange={(e) => handleUrlChecked(index, e)}
                                                        />
                                                    </div>
                                                    <input
                                                        type="url"
                                                        value={field.url}
                                                        className=''
                                                        readOnly
                                                    />
                                                    <span style={{ padding: "4px", marginRight: "15px", marginLeft: "15px" }}>
                                                        {field.char}
                                                    </span>
                                                    <button type="button" className="icn-btn tableimg p-0" onClick={() => handleDeleteField(field.url)}>
                                                        <i className="fa-solid fa-trash-can mx-2" style={{ color: 'red' }}></i>
                                                    </button>
                                                </div>
                                            ))} */}
                                    </div>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <div className='containers pb-2'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Q&A</strong></h4>
                                </div>
                                <div className='addmore'>
                                    {
                                        inputs == 0 ?
                                            ""
                                            :
                                            <button type="button" className="btn btn-outline-danger me-2" onClick={() => setInputs([])}>Delete all</button>
                                    }
                                    <button type="button" className="btn btn-primary " onClick={addInputField}>+</button>
                                </div>
                                {
                                    inputs == 0 ?
                                        ""
                                        :
                                        <div className="m-1 overflow-auto" style={{ maxHeight: '400px' }}>
                                            {inputs.map((pair, index) => (
                                                <div key={index} className="QuesAns m-2 d-flex align-items-center">
                                                    <div style={{ flex: 1 }}>
                                                        {index !== 0 ? (<hr />) : ''}
                                                        <label className="d-block mb-1">Title</label>
                                                        <input
                                                            type="text"
                                                            name="text_heading"
                                                            className="form-control m-0 h-0"
                                                            style={{ borderRadius: "10px" }}
                                                            placeholder='Title'
                                                            value={pair.text_heading}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                        />
                                                        <label className="d-block mb-1">Question</label>
                                                        <textarea
                                                            type="text"
                                                            name="question"
                                                            className="form-control m-0"
                                                            style={{ borderRadius: "10px" }}
                                                            placeholder='Question'
                                                            value={pair.question}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                        />
                                                        <label className="d-block mb-1">Answer</label>
                                                        <textarea
                                                            type="text"
                                                            name="answer"
                                                            className="form-control m-0"
                                                            style={{ borderRadius: "10px" }}
                                                            placeholder='Answer'
                                                            value={pair.answer}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                                                        onClick={() => deleteInputField(index)}
                                                    >
                                                        <img
                                                            src="/icons/deletebtn.svg"
                                                            style={{ width: '24px', height: '24px' }}
                                                            alt="Delete"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                }
                            </div>
                        </TabPanel>
                    </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-2 col-xl-3 sorcecontainerss'>
                    <div className='outer-space'>
                        <div className='containertwo p-3' style={{ background: "#fff" }}>
                            <h5><strong>Sources</strong></h5>
                            <div className='' style={{fontSize: "14px"}}>
                                <span ><strong>Total detected character</strong></span><br />
                                <span>{detectedChars}/{Team.plan?.subscription_config.embeddings} limit</span>
                            </div>
                            <div className=' my-2'><button type="button" onClick={value === 0 ? hanadleFileSubmit : value === 1 ? hanadleTextSubmit : value === 2 ? addUrlField : value === 3 ? hanadleQaSubmit : ''} className="btn btn-primary" style={{ color: '#fff' }}>Retrain Chatbot</button></div>
                        </div>
                    </div>
                </div>

                <div className='orline'><hr style={{ width: '40%' }} ></hr><p className='m-0'>Already included Files</p><hr style={{ width: '40%' }}></hr></div>
                <div className='' style={{ display: 'flex', alignItems: "center" }}>
                    <div className='' style={{ width: '70%' }} >
                        <form className='searchbartab mt-3 d-flex justify-content-start' onSubmit={handleSourceSearch}>
                            <input type="text" placeholder="Search Source" name="searchTerm" />
                            <button type="submit" className='border'><i className="fa fa-search"></i></button>
                        </form>
                    </div>
                    <div className='' style={{ width: '30%', textAlign: "end" }}><button type="button" className="btn btn-outline-danger" onClick={handleMultipleDelete}>Delete Selected</button></div>
                </div>

                {/* Doc/Tex/URL/QA --> Tables */}
                {value === 0 ? (
                    <div className='mt-5'>
                        <div className="table-responsive">
                            <table className="table-bordr">
                                <thead>
                                    <tr>
                                        <th className='wide-col'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => { handleMasterCheckboxChange(e, 'file') }}
                                            />
                                            <div className='ms-2'>Document Name</div>
                                        </th>
                                        <th>Chars/Chunks</th>
                                        <th>File Size</th>
                                        <th>Added Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Documents.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    data-id={item.id}
                                                    checked={!!item.checked}
                                                />
                                                <i className={`fa-regular ${item.status === 'Uploading' ? 'fa-solid fa-globe' : 'fa-file-pdf'} mx-3`}></i>
                                                {item.name}
                                            </td>
                                            <td>{item.no_of_characters}/{item.no_of_chunks}</td>
                                            <td>{item.size ? item.size : 0}KB</td>
                                            <td>{item.created_at}</td>
                                            <td>
                                                <span
                                                    className='text-truncate text-capitalize'
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: item.status == 'success' ? '#47a4e5' : item.status == 'pending' ? '#b9bbbd' : '#ff3346',
                                                        borderRadius: "20px",
                                                        padding: "8px 15px",
                                                    }}
                                                >
                                                    {item.status}
                                                </span>
                                            </td>
                                            <td>
                                                <button type="button" className="icn-btn" >
                                                    <img src="/icons/deletebtn.svg" className="tableimg" alt="logo" data-id={item.id} data-type='document' onClick={handleDelete} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                ) : value === 1 ? (
                    <div className='mt-5'>
                        <div className="table-responsive">
                            <table className="table-bordr">
                                <thead>
                                    <tr>
                                        <th className='wide-col'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => { handleMasterCheckboxChange(e, 'text') }}
                                            />
                                            <div className='ms-2'>Heading</div>
                                        </th>
                                        <th>Text</th>
                                        <th>Chars/Chunks</th>
                                        <th>Added Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Texts.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    data-id={item.id}
                                                    checked={!!item.checked}
                                                />
                                                <i className={`fa-regular ${item.status === 'Uploading' ? 'fa-solid fa-globe' : 'fa-file-pdf'} mx-3`}></i>
                                                {item.text_heading?.slice(0, 50)}
                                            </td>
                                            <td>{item.text?.slice(0, 50)}</td>
                                            <td>{item.no_of_characters}/{item.no_of_chunks}</td>
                                            <td>{item.created_at}</td>
                                            <td>
                                                <span
                                                    className='text-truncate text-capitalize'
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: item.status == 'success' ? '#47a4e5' : item.status == 'pending' ? '#b9bbbd' : '#ff3346',
                                                        borderRadius: "20px",
                                                        padding: "8px 15px"
                                                    }}
                                                >
                                                    {item.status}
                                                </span>
                                            </td>
                                            <td>
                                                <button type="button" className="icn-btn">
                                                    <img src="/icons/deletebtn.svg" className="tableimg" alt="logo" data-id={item.id} data-type='text' onClick={handleDelete} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                ) : value === 2 ? (
                    <div className='mt-5 sources-table'>
                        <div className="table-responsive">
                            <table className="table-bordr">
                                <thead>
                                    <tr>
                                        <th style={{paddingRight: "0px"}}><input
                                                className="me-2"
                                                type="checkbox"
                                                onChange={(e) => { handleMasterCheckboxChange(e, 'url') }}
                                            />

                                        </th>
                                        <th className='wide-col d-flex'>
                                            
                                            <div className='ms-2'  style={{paddingleft: "5px"}}>URL</div>
                                        </th>
                                        <th>Chars/Chunks</th>
                                        <th>Added Date</th>
                                        <th>Status</th>
                                        <th style={{paddingRight: "5px", paddingLeft: "5px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Websites.map((item, index) => (
                                        <tr key={item.id}>
                                            <td  style={{paddingRight: "0px"}}><input
                                                    className=""
                                                    type="checkbox"
                                                    data-id={item.id}
                                                    checked={!!item.checked}
                                                /></td>
                                            <td className='d-flex align-items-center custmtab' style={{paddingleft: "5px"}}>
                                                
                                                <i className={`fa-regular ${item.status === 'Uploading' ? 'fa-solid fa-globe' : 'fa-file-pdf'} mx-3`}></i>
                                                {item.url}
                                            </td>
                                            <td>{item.no_of_characters}/{item.no_of_chunks}</td>
                                            <td>{(item.created_at).substring(0, 10)}</td>
                                            <td>
                                                <span
                                                    className='text-truncate text-capitalize'
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: item.status == 'success' ? '#47a4e5' : item.status == 'pending' ? '#b9bbbd' : '#ff3346',
                                                        borderRadius: "20px",
                                                        padding: "4px 15px"
                                                    }}
                                                >
                                                    {item.status}
                                                </span>
                                            </td>
                                            <td  style={{paddingRight: "5px", paddingLeft: "5px"}}>
                                                <button type="button" className="icn-btn"  >
                                                    <img src="/icons/deletebtn.svg" className="tableimg" alt="logo" data-id={item.id} data-type='url' onClick={handleDelete} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                ) : value === 3 ? (
                    <div className='mt-5'>
                        <div className="table-responsive">
                            <table className="table-bordr">
                                <thead>
                                    <tr>
                                        <th className='wide-col'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => { handleMasterCheckboxChange(e, 'qa') }}
                                            />
                                            <div className='ms-2'>Heading</div>
                                        </th>
                                        <th>Question</th>
                                        <th>Answer</th>
                                        <th>Chars/Chunks</th>
                                        <th>Added Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Qas.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    data-id={item.id}
                                                    checked={!!item.checked}
                                                />
                                                <i className={`fa-regular ${item.status === 'Uploading' ? 'fa-solid fa-globe' : 'fa-file-pdf'} mx-3`}></i>
                                                {item.text_heading?.slice(0, 50)}
                                            </td>
                                            <td>{item.question?.slice(0, 50)}</td>
                                            <td>{item.answer?.slice(0, 50)}</td>
                                            <td>{item.no_of_characters}/{item.no_of_chunks}</td>
                                            <td>{item.created_at}</td>
                                            <td>
                                                <span
                                                    className='text-truncate text-capitalize'
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: item.status == 'success' ? '#47a4e5' : item.status == 'pending' ? '#b9bbbd' : '#ff3346',
                                                        borderRadius: "20px",
                                                        padding: "8px 15px"
                                                    }}
                                                >
                                                    {item.status}
                                                </span>
                                            </td>
                                            <td>
                                                <button type="button" className="icn-btn" >
                                                    <img src="/icons/deletebtn.svg" className="tableimg" alt="logo" data-id={item.id} data-type='qa' onClick={handleDelete} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                ) : ''}
            </div>
        </>
    );
}

export default Sources;