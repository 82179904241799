import Swal from 'sweetalert2';
import '../../Admin/style.css';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../TabContextProvider';
import { NavLink, useNavigate } from 'react-router-dom';

function Adminheader({ toggleNavbar, isNavbarVisible }) {

  const {User, setUser} = useContext(UserContext);
  const [imageSrc, setImageSrc] = useState('/assets/useravatar.png');
  const navigate = useNavigate();

  const handleLogout = function (e) {
    setUser({})
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    navigate('/');
    Swal.fire({
      title: "Logout Successfull.",
      icon: 'success',
      showConfirmButton: false,
      timer: 3000
    })
  };

  useEffect(() => {
    if (User?.profile_pic) {
        setImageSrc(User.profile_pic)
    }
}, [User])

  return (
    <>
      <div className="header-sec mb-0">
      <button 
          className="btn toggle-btn headtoggle" 
          onClick={toggleNavbar}
        >
          {/* {isNavbarVisible ? 'Hide Navbar' : 'Show Navbar'} */}
          <i className="fa-solid fa-bars "></i>
        </button>
      
        <div className='headerlogo-sec' onClick={()=>navigate("/admin-dashboard")}>
          <img src="/assets/Layer_1.png" className="logo" alt="logo" style={{ width: '80%' }} />
        </div>
        <div className="input-group">
          <div className='Adheadname'>
            <h4>Admin Dashboard</h4>
          </div>
          {/* <form className='searchbar'>
            <input type="text" placeholder="Search.." name="search" />
            <button type="submit"><i className="fa fa-search"></i></button>
          </form> */}
        </div>

        <div className='mobdrop'>
          <div className="dropdown" style={{ display: "flex" }}>
            <img src={imageSrc || '/assets/profile-user.png'} alt="user-logo" style={{ width: "40px" }}></img>
            <button className="btn dropdown-toggle pe-4" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              {User?.email}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><NavLink className="dropdown-item" to="/admin-dashboard">Dashboard</NavLink></li>
              <li><NavLink className="dropdown-item" to="/admin-Setting">Settings</NavLink></li>
              <li><NavLink className="dropdown-item" to="/Seo-Setting">Seo Setting</NavLink></li>
              <li><div className="dropdown-item" onClick={handleLogout}>Log out</div></li>
            </ul>
          </div>
        </div>

        <div className='deskdrop'>
          <div className="dropdown" style={{ display: "flex" }}>
            <img src={imageSrc || '/assets/profile-user.png'} alt="user-logo" style={{ width: "40px" }}></img>
            <button className="btn dropdown-toggle pe-4" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              {User?.email}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><NavLink className="dropdown-item" to="/admin-dashboard">Dashboard</NavLink></li>
              <li><NavLink className="dropdown-item" to="/admin-Setting">Settings</NavLink></li>
              <li><NavLink className="dropdown-item" to="/Seo-Setting">Seo Setting</NavLink></li>
              <li><div className="dropdown-item" onClick={handleLogout}>Log out</div></li>
            </ul>
          </div>
        </div>

      </div>
    </>
  );
}

export default Adminheader;
