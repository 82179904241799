import { useNavigate, useParams } from 'react-router-dom';
import '../App.css';
import React, { useEffect, useRef, useState } from 'react';
import UserApiClient from '../ApiClients';
import DOMPurify from "dompurify";
import parse from 'html-react-parser';
import Swal from 'sweetalert2';
import { BaseApiUrl } from '../Configs/enviroment';

function Iframe() {

    const [appearanceData, setAppearanceData] = useState({});
    const [userMsgStyle, setUserMsgStyle] = useState({});
    const [botMsgStyle, setBotMsgStyle] = useState({});
    const [inputText, setInputText] = useState('');
    const [visitorId, setVisitorId] = useState(null);
    const [visitor_load_form_status, set_visitor_load_form_status] = useState(null);
    const [chatbotLanguage, setChatbotLanguage] = useState('en');
    const [isGenerating, setisGenerating] = useState(false);
    const [chat, setChat] = useState([]);
    const scrollbarRef = useRef();
    const navigator = useNavigate();
    const params = useParams();
    let isFetching = false;
    const chatbotId = params.id;

    const fetchData = async () => {

        isFetching = true;
        // Fetch IP data
        let ipResponse = await fetch("https://api.ipify.org?format=json");
        if (!ipResponse.ok) throw new Error("Failed to fetch IP data");
        let ipData = await ipResponse.json();
        let IP = ipData.ip;

        // Fetch location data
        let locationResponse = await fetch(`https://ipapi.co/${IP}/json`);
        if (!locationResponse.ok) throw new Error("Failed to fetch location data");
        let locationData = await locationResponse.json();
        let locationArea = `${locationData.city}, ${locationData.region}, ${locationData.country}`;

        // Send visitor data to the server
        let serverResponse = await fetch(BaseApiUrl + "bot/widget/visitor/track/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: window.location.href,
                location: locationArea,
                ipAddress: IP,
                botId: chatbotId,
            })
        });

        if (!serverResponse.ok) throw new Error("Failed to send visitor data to server");
        let response = await serverResponse.json();

        setVisitorId(response.visitor_id);
        set_visitor_load_form_status(response.load_form_status);
        setChatbotLanguage(response.language);

        UserApiClient.get(`bot/widget/chat/appearances/${chatbotId}/`, {}, false, true, false).then(res => {
            if (!res.success) navigator('/error');
            setAppearanceData(res.data);
            isFetching = false;
        });
    }

    useEffect(() => {
        if (!isFetching && chatbotId) fetchData();
    }, [])

    useEffect(() => {
        if (Object.values(appearanceData).length > 0) {
            setUserMsgStyle({ color: appearanceData.user_message_color, backgroundColor: appearanceData.user_message_background });
            setBotMsgStyle({ color: appearanceData.bot_message_color, backgroundColor: appearanceData.bot_message_background });
            document.body.style.backgroundColor = appearanceData.chatbot_background_color;
            if (appearanceData.chatbot_background_image) {
                document.body.style.backgroundImage = `url('${BaseApiUrl + appearanceData.chatbot_background_image}')`;
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";
                document.body.style.backgroundPosition = "top";
                document.body.style.backgroundPositionY = "4rem";
                document.body.style.height = "90vh";
            }
        }
    }, [appearanceData])

    useEffect(() => {
        setTimeout(() => {
            if (scrollbarRef.current) {
                scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight;
            }
        }, 100); // Delay might be needed to wait for the DOM to update
    }, [chat])

    const textPatternFormatting = (text) => {
        let pattern = /^(###.*$)|\*\*(.*?)\*\*|\[(.*?)\]\((.*?)\)/gm;
        let result = text.replace(pattern, function (match, group1, group2, group3, group4) {
            if (group1) {
                return "<b>" + group1.replace(/^###\s*/, "") + "</b>";
            } else if (group2) {
                return "<b>" + group2 + "</b>";
            } else if (group3 && group4) {
                return '<a href="' + group4 + '" target="_blank" style="color: blue;">' + group3 + '</a>';
            }
        });
        return result;
    };

    const appendMessage = (text, sender) => {
        setChat((prevMessages) => [
            ...prevMessages,
            {
                text: text,
                sender: sender, // 'user' or 'bot'
            },
        ]);
    };

    const handleMessageSubmit = async () => {
        if (!inputText.trim()) return;

        setisGenerating(true);
        appendMessage(inputText, "user");

        let payload = {
            question: inputText,
            chatbot_id: chatbotId,
            visitor_id: visitorId
        };

        setInputText("");

        const response = await fetch(`${BaseApiUrl}bot/widget/chat/response/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        setisGenerating(false);

        if (!response.ok || !response.body) {
            if (response.status == 426) {
                Swal.fire({
                    icon: 'warning',
                    title: "Limit exceed for messages, please upgrade your plan.",
                    showCancelButton: false,
                    timer: 3000
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: response.statusText,
                    showCancelButton: false,
                    timer: 3000
                })
            }

            if (chat.length > -1) {
                setChat(prevList => prevList.slice(0, prevList.length - 1));
            }
            return;
        }

        appendMessage("", "bot");
        processStream(response);
    };

    const processStream = async (response) => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let fullResponse = "";

        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                break;
            }
            const decodedChunk = decoder.decode(value, { stream: true });
            fullResponse += decodedChunk;
            const formattedText = textPatternFormatting(fullResponse); // Define this function as needed

            setChat((prev) => {
                const lastIndex = prev
                    .map((itm, index) => ({ itm, index }))
                    .filter(({ itm }) => itm.sender === "bot")
                    .pop()?.index;
                if (lastIndex !== undefined && lastIndex >= 0) {
                    return prev.map((itm, index) => {
                        if (index === lastIndex) {
                            return { ...itm, text: formattedText };
                        }
                        return itm;
                    });
                }
                return prev;
            });
        }
    };


    return (
        <>
            <div className='col-12 p-0'>
                <div className="iframeprexvbox">
                    <div className="iframepreview" style={{ justifyContent: "space-between", backgroundColor: appearanceData.top_bar_background ? appearanceData.top_bar_background : "rgb(63, 164, 232)" }}>
                        <div className="prev-head" style={{ fontSize: appearanceData.font_size ? appearanceData.font_size : '16px', color: appearanceData.top_bar_textcolor ? appearanceData.top_bar_textcolor : 'rgb(255, 255, 255)' }}>
                            <img src={appearanceData.chatbot_logo ? appearanceData.chatbot_logo : "https://api.nimibot.com//media/default_image/2.png"} style={{ width: "25px" }} />{appearanceData.display_name || "John"}</div>
                        <div>
                            <button type="button" className="btn" onClick={() => setChat([])}><i className="fas fa-sync p-1" style={{ fontSize: appearanceData.font_size ? appearanceData.font_size : '16px', color: appearanceData.top_bar_textcolor ? appearanceData.top_bar_textcolor : 'rgb(255, 255, 255)' }}></i></button>
                        </div>
                    </div>
                    <div className="iframechatbot__box">
                        <div className="iframechat-content">
                            <div className="chat-message bot-message">
                                <div className="user-text-message" style={{ position: "unset" }}>
                                    <div className="bot-message-container">
                                        <img src={appearanceData.chatbot_image ? appearanceData.chatbot_image : "https://api.nimibot.com//media/default_image/1.png"} style={{ width: "25px" }} />
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="bot-text" style={botMsgStyle}>{appearanceData.initial_message ? appearanceData.initial_message : "Hi! What can I help with you ?"}</p>
                                    </div>
                                </div>
                            </div>
                            {chat && chat.map((item, index) => (
                                <div key={index}>
                                    {item.sender == 'user' ? (
                                        <div className="chat-message user-message">
                                            <div className="client-text-message">
                                                <p className="user-text" style={userMsgStyle}>{item.text}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="chat-message bot-message">
                                            <div className="user-text-message" style={{ position: "unset" }}>
                                                <div className="bot-message-container">
                                                    <img src={appearanceData.chatbot_image ? appearanceData.chatbot_image : "https://api.nimibot.com/media/default_image/1.png"} style={{ width: "25px" }} />
                                                </div>
                                                <div className="flex flex-col">
                                                    <div className="bot-text" style={botMsgStyle}>
                                                        <pre style={{
                                                            lineHeight: '1.3',
                                                            textWrap: "wrap",
                                                            fontFamily: "inherit",
                                                            fontSize: "100%",
                                                            margin: "0px",
                                                            ...botMsgStyle
                                                        }}>
                                                            {parse(`${DOMPurify.sanitize(item.text)}`)}
                                                        </pre>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div className={`pb-2 ${isGenerating ? "" : "d-none"}`}>
                                <div className="chat-message bot-message">
                                    <div className="user-text-message" style={{ position: "unset" }}>
                                        <div className="bot-message-container">
                                            <img src={appearanceData.chatbot_image ? appearanceData.chatbot_image : "https://api.nimibot.com//media/default_image/1.png"} style={{ width: "25px" }} />
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="bot-text" style={botMsgStyle}>
                                                <div className='ai_thinking' style={{
                                                    "--_g": `no-repeat radial-gradient(circle closest-side, ${botMsgStyle.color} 90%, #0000)`
                                                }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ position: "fixed", bottom: "0", width: '100%' }}>
                        <div className="iframeinterfacemsgbox prevmsgbox" style={{ backgroundColor: appearanceData.top_bar_background ? appearanceData.top_bar_background : "rgb(63, 164, 232)" }}>
                            <input type="text" className="form-control me-1" id="exampleInputtext" aria-describedby="text" placeholder="Message..." style={{ borderRadius: '10px' }} value={inputText} onChange={(event) => { setInputText(event.target.value) }} onKeyUp={(event) => { if (event.key === "Enter") { handleMessageSubmit() } }} autoFocus />
                            <button className="Prevsend-button" style={{ backgroundColor: appearanceData.top_bar_background ? appearanceData.top_bar_background : "rgb(63, 164, 232)" }} onClick={handleMessageSubmit}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.74928 11.2501L17.4993 2.50014M8.85559 11.5235L11.0457 17.1552C11.2386 17.6513 11.3351 17.8994 11.4741 17.9718C11.5946 18.0346 11.7381 18.0347 11.8587 17.972C11.9978 17.8998 12.0946 17.6518 12.2881 17.1559L17.78 3.08281C17.9547 2.63516 18.0421 2.41133 17.9943 2.26831C17.9528 2.1441 17.8553 2.04663 17.7311 2.00514C17.5881 1.95736 17.3643 2.0447 16.9166 2.21939L2.84349 7.71134C2.34759 7.90486 2.09965 8.00163 2.02739 8.14071C1.96475 8.26129 1.96483 8.40483 2.02761 8.52533C2.10004 8.66433 2.3481 8.7608 2.84422 8.95373L8.47589 11.1438C8.5766 11.183 8.62695 11.2026 8.66935 11.2328C8.70693 11.2596 8.7398 11.2925 8.7666 11.3301C8.79685 11.3725 8.81643 11.4228 8.85559 11.5235Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </button>
                        </div>

                        <div className="iframeinterfacemsgbox d-flex justify-content-center" style={{ backgroundColor: appearanceData.footer_background_color ? appearanceData.footer_background_color : "rgb(63, 164, 232)", borderTop: "1px solid rgb(255, 255, 255)" }}>
                            <div style={{ color: appearanceData.footer_text_color ? appearanceData.footer_text_color : "rgb(255, 255, 255)", fontSize: "16px" }}><img src="/assets/Logo3.png" style={{ width: "20px" }} />&nbsp;{appearanceData.footer_text || "Powered By Nimibot AI"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Iframe;
