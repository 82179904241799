import './App.css';
import Home from './Components/Home';
import Login from './Covers/Login';
import Dashboard from './Components/Dashboard';
import AdminLogin from './Admin/cover/Login';
import Payment from './Admin/cover/Payment.jsx';
import Forget from './Covers/forget';
import Signup from './Covers/signup';
import Layout from './Components/Layout';
import AdminLayout from './Admin/adminComp/layout';
import AdminDashboard from './Admin/adminComp/AdminDashboard';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Blogs from './Admin/adminComp/blog';
import BlogCategory from './Admin/adminComp/BlogCategory';
import CreateBlog from './Admin/adminComp/createblog';
import SingleBlog from './Admin/adminComp/singleblog';
import Categorylist from './Admin/adminComp/Categorylist';
import Allusers from './Admin/adminComp/users/alluser';
import Activeusers from './Admin/adminComp/users/activeuser';
import Inactiveusers from './Admin/adminComp/users/inactiveuser';
import Adduser from './Admin/adminComp/users/adduser';
import Userprofiles from './Admin/adminComp/users/userprofiles';
import Invoice from './Admin/adminComp/Invoice/invoice';
import Userinvoice from './Admin/adminComp/Invoice/userinvoice';
import FAQ from './Admin/adminComp/FAQ/faq';
import AdminSetting from './Admin/adminComp/adsetting/AdminSetting';
import SEOSetting from './Admin/adminComp/adsetting/SeoSetting';
import { UserContext } from './TabContextProvider';
import React, { useContext, useState, useEffect } from 'react';
import GoogleCallBack from './Covers/GoogleCallBack';
import TermsOfService from './Covers/TermsOfService.jsx';
import PrivacyPolicy from './Covers/PrivacyPolicy.jsx';
import Contactus from './Covers/contact.jsx';
import Compare from './Covers/Compare.jsx';
import ResetForgetPass from './Covers/resetPass.jsx';
import Iframe from './Covers/iframe.jsx';
import TeamMemberAccept from './Covers/memberAccept.jsx';
import NewLetter from './Admin/adminComp/news-letter.jsx';

function App() {
  const token = localStorage.getItem('token');
  const { User } = useContext(UserContext);

  const [checkUser, setCheckUser] = useState(!!token && !User?.is_superuser);
  const [checkAdmin, setCheckAdmin] = useState(!!token && User?.is_superuser);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setCheckUser(!!token && !User?.is_superuser);
    setCheckAdmin(!!token && User?.is_superuser);
  }, [User])

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forget-pass" element={<Forget />} />
        <Route path="/reset-forget-pass/" element={<ResetForgetPass />} />
        <Route path="/home" element={<Home />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/google-callback" element={<GoogleCallBack />} />
        <Route path="/member/accept/" element={<TeamMemberAccept />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/Contact-Us" element={<Contactus />} />
        <Route path="/Compare-plans" element={<Compare />} />
        <Route path="/chatbot-iframe/:id/" element={<Iframe />} />

        {/* Protected Routes */}
        {/* { 1 == 1 ? ( */}
        {checkUser ? (
          <>
            {/* User Routes */}
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard/:team_url" element={<Layout />} />
            <Route path="/dashboard/" element={<Layout />} />
            <Route path="/layout/:team_url/:chatbot_name/:chatbot_id" element={<Layout />} />
            <Route path="/settings" element={<Layout />} />
            <Route path="/help" element={<Layout />} />
            <Route path="/payment" element={<Payment />} />
          </>
        ) : (
          <Route path="/" element={<Dashboard />} />
        )}
        {/* { 1 == 1 ? ( */}
        {checkAdmin ? (
          <>
            {/* Admin Routes */}
            <Route path="/adminlayout" element={<AdminLayout />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog-category" element={<BlogCategory />} />
            <Route path="/create-blog" element={<CreateBlog />} />
            <Route path="/single-blog" element={<SingleBlog />} />
            <Route path="/category-list" element={<Categorylist />} />
            <Route path="/all-user" element={<Allusers />} />
            <Route path="/user-profile/:id" element={<Userprofiles />} />
            <Route path="/active-user" element={<Activeusers />} />
            <Route path="/inactive-user" element={<Inactiveusers />} />
            <Route path="/adduser" element={<Adduser />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/user-invoice" element={<Userinvoice />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/news-letter" element={<NewLetter />} />
            <Route path="/admin-setting" element={<AdminSetting />} />
            <Route path="/seo-setting" element={<SEOSetting />} />
          </>
        ) : (
          <></>
        )}
        {/* Redirect unknown paths */}
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
